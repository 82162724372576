import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import {
  Menu,
  Container,
  Button,
  Responsive,
  Segment
} from 'semantic-ui-react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import SignedOutMenu from '../Menus/SignedOutMenu';
import SignedInMenu from '../Menus/SignedInMenu';
import { openModal } from '../../modals/modalActions';
// import NavMobile from './NavMobile';
import './Navmobile.css';

class NavBar extends Component {
  state = {
    openClass: false
  };

  handleSignIn = () => {
    this.props.openModal('LoginModal');
  };
  handleRegister = () => {
    this.props.openModal('RegisterModal');
  };
  handleSignOut = () => {
    this.props.firebase.logout();
    this.props.history.push('/');
  };

  handleHamburger = () => {
    console.log('hamburger');
    this.setState({
      openClass: !this.state.openClass
    });
  };

  render() {
    const { auth, profile } = this.props;
    const authenticated = auth.isLoaded && !auth.isEmpty;
    console.log('open: ' + this.state.openClass);
    return (
      <Fragment>
        {/* <NavMobile /> */}

        <div className="hamburger " onClick={() => this.handleHamburger()}>
          <span className="hamburger-bun"></span>
          <span className="hamburger-patty"></span>
          <span className="hamburger-bun"></span>
        </div>

        <Menu
          inverted
          fixed="top"
          className={
            this.state.openClass
              ? 'ui pointing menu stackable open'
              : 'ui pointing menu stackable close'
          }
        >
          <Container>
            <Menu.Item as={NavLink} exact to="/" header className="item">
              <img src="/assets/logo.png" alt="logo" />
              {/* <Segment.Group>
                <Responsive as={Segment} minWidth={768}>
                  Re-vents
                </Responsive>
              </Segment.Group> */}
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              exact
              to="/events"
              name="Events"
              className="item"
            />
            {authenticated && (
              <Fragment>
                <Menu.Item as={NavLink} exact to="/people" name="People" />
                <Menu.Item as={NavLink} exact to="/test" name="Test" />
                <Menu.Item as={Link} to="/createEvent">
                  <Button
                    floated="right"
                    positive
                    inverted
                    content={`Create Event`}
                  />
                </Menu.Item>
              </Fragment>
            )}

            {authenticated ? (
              <SignedInMenu
                auth={auth}
                signOut={this.handleSignOut}
                profile={profile}
              />
            ) : (
              <SignedOutMenu
                signIn={this.handleSignIn}
                register={this.handleRegister}
              />
            )}
          </Container>
        </Menu>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  openModal
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

export default withRouter(
  withFirebase(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(NavBar)
  )
);
